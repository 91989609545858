import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CallToAction from "../components/cta"
import InfoBullet from "../components/infoBullet"
import Hero from "../components/hero"

const WheelTitleStyles = styled.div`
  margin: 5em 0;
  text-align: center;
`

const WheelTypeTemplate = ({ data }) => {
  const wheelType = data.wheelType
  const hero = wheelType.childMdx.frontmatter.hero
  const bullets = wheelType.childMdx.frontmatter.bullets
  return (
    <Layout>
      <SEO
        title={wheelType.childMdx.frontmatter.type}
        description={wheelType.childMdx.frontmatter.homeDescription}
        keywords={[
          `carbon wheels`,
          `carbon fiber wheels`,
          `carbon bicycle wheels`,
          `high performance bicycle wheels`,
          `bike wheels`,
          `bmx cruiser wheels`,
          `29er bmx`,
          `fixed gear wheels`,
          `fixed wheels`,
          `fixie wheels`,
          `track wheels`,
          `road bike wheels`,
          `carbon wheelset`,
          `usa made wheels`,
        ]}
      />
      <Hero image={hero.image} title={hero.title}>
        <h1>{hero.title}</h1>
        <p>{hero.description}</p>
        <CallToAction text="Shop Now" link="/shop/" />
      </Hero>
      <WheelTitleStyles>
        <h1>Each Wheel Handmade in the USA</h1>
      </WheelTitleStyles>
      <div className="bullet-points">
        {bullets.map((bullet, index) => {
          const bulletImage = getImage(bullet.image)
          return (
            <InfoBullet key={index} title={bullet.title} image={bulletImage}>
              <h2>{bullet.title}</h2>
              <p>{bullet.description}</p>
            </InfoBullet>
          )
        })}
      </div>
    </Layout>
  )
}

export default WheelTypeTemplate

export const query = graphql`
  query ($slug: String!) {
    wheelType: file(name: { eq: $slug }) {
      name
      childMdx {
        id
        frontmatter {
          type
          shopifyTag
          homeDescription
          hero {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, height: 600, layout: FULL_WIDTH)
              }
            }
          }
          bullets {
            title
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90, height: 300, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
